import {Subscription} from 'rxjs';
// Angular
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
// Layout
import {LayoutConfigService, SplashScreenService, TranslationService} from './core/_base/layout';
// language list
import {locale as arLang} from './core/_config/i18n/ar';
import {locale as enLang} from './core/_config/i18n/en';
import {locale as chLang} from './core/_config/i18n/ch';
import {locale as esLang} from './core/_config/i18n/es';
import {locale as jpLang} from './core/_config/i18n/jp';
import {locale as deLang} from './core/_config/i18n/de';
import {locale as frLang} from './core/_config/i18n/fr';
import {WINDOW} from "./core/_base/crud/providers/window.provider";
import {CompanyService} from "./core/_base/crud/services/company/company.service";
import {DOCUMENT} from "@angular/common";
import {Title} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WebBookingService} from "./resolvers/webBooking/web-booking.service";
import {environment} from "../environments/environment";
declare const gtag: Function;
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    // Public properties
    title = 'Skiplino';
    loader: boolean;
    company;
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    hostName;

    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     * @param companyService
     * @param window
     * @param _document
     * @param titleService
     * @param layoutConfigService: LayoutConfigService
     * @param splashScreenService: SplashScreenService
     * @param modalService
     * @param aRouter
     * @param webBookingService
     */
    constructor(
        private translationService: TranslationService,
        private router: Router,
        private companyService: CompanyService,
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private titleService: Title,
        private layoutConfigService: LayoutConfigService,
        private splashScreenService: SplashScreenService,
        private modalService: NgbModal,
        public aRouter: ActivatedRoute,
        public webBookingService: WebBookingService) {
        // this.favIcon.href = './favicon_path_folder/favicon.ico';
        // register translations


        if(this.window.location.hostname ==='skiplino.live')
        { this.router.navigate(['/landing']);}

        this.company = webBookingService.getCompany();
        if (this.company) {
            this.setLangFromUrl(this.company?.languages);
            // const companyClass = `company_${this.company.webbooking?.url.split('.').join('_')}`;
            const companyClass = `company_${this.company._id}`;
            document.body.classList.add(companyClass);

            // let primaryColor = '1575DA';
            // let cssStyles = `
            //     .${companyClass}{
            //     --primary-color: #${primaryColor};
            //     --primary-color-light: #${primaryColor+'88'};
            // }`;
            // let head = document.head || document.getElementsByTagName('head')[0];
            // let style = document.createElement('style');
            // head.appendChild(style);

            // style.type = 'text/css';
            // style.appendChild(document.createTextNode(cssStyles));

           if (window.location.href.indexOf("qrBooking") > -1)
            {
                //console.log(window.location.href.match(/.*\/([^/]+)\/[^/]+/)[0]);
                this._document.getElementById('appFavicon').setAttribute('href', './assets/media/skiplino/favicon.png');
                this.titleService.setTitle(this.company?.name || 'Skiplino | Dynamic Ticketing');
                this.initiateGoogleTagManager('G-JNMHCDMR55', true);
            }
           else {
               this.initiateGoogleTagManager('G-LKXRZ2S4JS');
               // check if webbooking is active
               if (!this.company.webbooking?.active) {
                   this.router.navigate(['error/error-3']);
               } else {
                   // company is exist and active
                   // if current url in error or video page go to main page.
                   // console.log(/[^/]*$/.exec(window.location.href));
                   if (/[^/]*$/.exec(window.location.href).length > 1 && (/[^/]*$/.exec(window.location.href)[0] === 'error-2' || window.location.href.match(/.*\/([^/]+)\/[^/]+/)[1] === 'video')) {
                       this.router.navigate(['/']);
                   }
                   this._document.getElementById('appFavicon').setAttribute('href', this.company?.webbooking?.options?.favIcon || './assets/media/skiplino/favicon.png');
                   this.titleService.setTitle(this.company?.webbooking?.options?.pageTitle || 'Skiplino | Booking');
               }
           }
        } else {
            if (environment.production) {
                // @ts-ignore
                if (!this.window.location.hostname === 'video') {
                    this.router.navigate(['error/error-3']);
                }
            } else {
                // @ts-ignore
                if (!localStorage.getItem('video') === 'yes') {
                    this.router.navigate(['error/error-3']);
                }
            }
        }
        this.translationService.loadTranslations(arLang, enLang, chLang, esLang, jpLang, deLang, frLang);

    }


    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {

        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('page-loader.type');
        // let hostName = this.window.location.hostname;

        const routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    initiateGoogleTagManager(trackingID, qrBooking = false){
        if (environment.production)
        {
            let gtagScript = document.createElement('script');
            gtagScript.setAttribute('type', 'text/javascript');
            gtagScript.setAttribute('async', 'true');
            gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

            let gtagScript2 = document.createElement('script');
            gtagScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;
            document.documentElement.firstChild.appendChild(gtagScript);
            document.documentElement.firstChild.appendChild(gtagScript2);

            if (qrBooking) {
                gtag('event', 'DynamicTicketing', {
                    event_category: 'DynamicTicketing',
                    event_label: this.company.name,
                });
            } else {
                let hostName = this.window.location.hostname;
                gtag('event', 'WebBooking', {
                    event_category: 'WebBooking',
                    event_label: hostName,
                });
            }
        }
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }
    setLangFromUrl(companyLangs) {

        const languages =  companyLangs || [
            {
                code: "en",
                flag: "GB",
                id: "1",
                name: "English",
                nameInEnglish: "English",
                nameIn_en: "English",
                nameIn_ar: "الإنجليزية",
                nameIn_pt: "Inglês",
            }, 
            {
                id: "2",
                code: "ar",
                flag: "SA",
                name: "العربية",
                nameInEnglish: "Arabic",
                nameIn_en: "Arabic",
                nameIn_ar: "العربية",
                nameIn_pt: "Árabe",
            },
            {
                id: "46",
                code: "pt",
                flag: "PT",
                name: "português",
                nameInEnglish: "Portuguese",
                nameIn_en: "Portuguese",
                nameIn_ar: "البرتغالية",
                nameIn_pt: "português",                
            },
        ];

        const _returnlanguage = (langs, lang) => {
            for (let i = 0; i < langs.length; i++) {
                if(langs[i].code === lang) {
                    return langs[i];
                }
            }
            return;
        }

        
        // 1. get local from Params
        const urlParams = new URLSearchParams(this.window.location.search);
        const local = urlParams.get('locale');
        if (!local) return false;

        // 2. get check if this language is in the company languages
        const lang = _returnlanguage(languages, local);
        // console.log('=> check if this language is in the company languages: ', lang);
        if (!lang) return false;        
        
        // 3. set language on local storage
        localStorage.setItem('defaultLanguage', JSON.stringify(lang));
    }
}
