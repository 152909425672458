// Angular
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService, currentUser, Login} from '../../../../core/auth';
// 3rd party

import {CountdownComponent, CountdownConfig, CountdownEvent} from "ngx-countdown";
import {Intercom} from "ng-intercom";
import {SplitInputService} from "ngx-splitinput";
import {BranchService} from "../../../../core/_base/crud/services/branch/branch.service";
import {CompanyService} from "../../../../core/_base/crud/services/company/company.service";
import {PrebookingPopupComponent} from "../../../partials/content/skiplino/prebooking-popup/prebooking-popup.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatSnackBar} from "@angular/material/snack-bar";
import {WINDOW} from "../../../../core/_base/crud/providers/window.provider";
import {WebBookingService} from "../../../../resolvers/webBooking/web-booking.service";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'kt-verification',
    templateUrl: './verification.component.html',
    encapsulation: ViewEncapsulation.None
})
export class VerificationComponent implements OnInit {

    // Public params
    verificationForm: FormGroup;
    loading = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];
    mobileNumber: string;
    private unsubscribe: Subject<any>;
    @ViewChild('cdmsg', {static: false}) private countdownMsg: CountdownComponent;
    @ViewChild('cdcall', {static: false}) private countdownCall: CountdownComponent;
    @ViewChild('cdchat', {static: false}) private countdownChat: CountdownComponent;
    configMsg: CountdownConfig = {leftTime: environment.production ? 120 : 10, format: 'mm:ss'};
    configCall: CountdownConfig = {leftTime: environment.production ? 120 : 10, format: 'mm:ss', demand: true};
    configChat: CountdownConfig = {leftTime: environment.production ? 120 : 10, format: 'mm:ss', demand: true};
    private returnUrl: any;
    msgCountEnd: boolean = false;
    callCountEnd: boolean = false;
    chatCountEnd: boolean = false;
    disableResend: boolean = false;
    disableCall: boolean = false;

    disableAfterClickMsg = false;
    disableAfterClickCall = false;
    disableAfterClickChat = false;
    showResendSms = false;
    showCall = false;
    showChat = false;
    altMethods = [
        {
            sms: {
                values: ['firebase', 'twilioSms', 'skiplinoSMS'],
                shortcut: this.translate.instant('PAGES.VERIFICATION.RESEND_CODE'),
                icon: 'flaticon2-sms'
            }
        },
        {call: {values: ['twilioCall', 'nexmoCall'], shortcut: this.translate.instant('PAGES.VERIFICATION.CALL_ME'), icon: "flaticon2-phone"}},
        {whatsapp: {values: ['skiplinoWhatsapp'], shortcut: this.translate.instant('PAGES.VERIFICATION.SEND_WHATSAPP'), icon: "flaticon2-chat"}}

    ];
    _altMethodsQueue = [];
    verificationConfig;
    queryParamsUrl;
    currentLanguage;
    // Read more: => https://brianflove.com/2016/12/11/angua

    /**
     * Component constructor
     *
     * @param router: Router
     * @param auth: AuthService
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     * @param route
     */
    constructor(
        private router: Router,
        private branchService: BranchService,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        public webbookingService: WebBookingService,
        private modalService: NgbModal,
        private intercom: Intercom,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private splitInputService: SplitInputService,
        public companyService: CompanyService,
        @Inject(WINDOW) private window: Window
    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.currentLanguage = JSON.parse(localStorage.getItem('defaultLanguage'));
        this.mobileNumber = this.route.snapshot.paramMap.get('mobileNumber');
        this.initVerificationForm();
        this.queryParamsUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
        // redirect back to the returnUrl before login
        // this.route.queryParams.subscribe(params => {
        //     this.returnUrl = params.returnUrl || '/';
        // });

        this.getConf();
        // this.countdown.begin();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initVerificationForm() {
        this.verificationForm = this.fb.group({
            verificationCode: new FormControl(undefined, [Validators.required,
                Validators.minLength(6),
                Validators.maxLength(6),
                Validators.pattern("^[0-9]*$")])
        });
    }


    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.verificationForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }


    handleEventMsg(e: CountdownEvent) {
        if (e.action === 'done') {
            this.msgCountEnd = true;
        }
    }

    handleEventCall(e: CountdownEvent) {
        if (e.action === 'done') {
            this.callCountEnd = true;
        }
    }

    handleEventChat(e: CountdownEvent) {
        if (e.action === 'done') {
            this.chatCountEnd = true;
        }
    }

    updateVerification(to) {
        const updateVerification = to === 'email' ? {
            "method": to,
            "authEmail": this.mobileNumber,
        } : {
            "method": to,
            "mobile": this.mobileNumber,
        };

        if (to === 'email') {
            this.disableAfterClickMsg = true;
        } else if (to === 'twilioSms') {
            this.disableAfterClickMsg = true;
            this.showCall = true;
            this.countdownCall.begin();
        } else if (to === 'nexmoCall') {
            this.disableAfterClickCall = true;
            this.showChat = true;
            this.countdownChat.begin();
        } else if (to === 'skiplinoWhatsapp') {
            this.disableAfterClickChat = true;
        }

        this.cdr.markForCheck();

        this.auth.updateVerificationMethod(updateVerification).then((res) => {
            if (to === 'nexmoCall') {
                this.snackBar.open(this.translate.instant('PAGES.VERIFICATION.CALL_MSG'), null, {
                    direction: this.currentLanguage?.code === 'ar' ? 'rtl' : 'ltr',
                    duration: 5000,
                });
            } else {
                this.snackBar.open(this.translate.instant('PAGES.VERIFICATION.SENT_SUCCESS'), null, {
                    direction: this.currentLanguage?.code === 'ar' ? 'rtl' : 'ltr',
                    duration: 5000,
                });
            }

            this.loading = false;
            this.cdr.markForCheck();
        }, (error) => {
            this.loading = false;
            this.cdr.markForCheck();
        }).finally(() => {
            this.cdr.markForCheck();
        });
    }

    enableChat() {
        this.disableAfterClickChat = true;
        this.intercom.boot({
            widget: {
                "activator": "#intercom"
            }
        });
        this.intercom.show();
    }


    handleCompleted(event: any): void {
        this.splitInputService.clearSplitInput();
        this.loading = true;

        let verifyData = {};
        if (!this.isEmail(this.mobileNumber)) {
            verifyData = {
                code: event,
                method: "twilioSms",
                mobile: this.mobileNumber
            };
        } else {
            verifyData = {
                code: event,
                method: "email",
                authEmail: this.mobileNumber
            };
        }

        this.auth.verifyNumber(verifyData).then(async (res) => {
            let isVerificationByEmail = this.isEmail(this.mobileNumber);
            let customer = res.data;
            localStorage.setItem('tok', res.data?.token); 
            this.store.dispatch(new Login({authToken: customer._id}));

            // get current user.
            // if email/mob ver required and user not verified then verify. with optional (skip)
            // if user choose to verify. on success -> mark current user as verified && show the popup 'The email was verified successfully. this email will be used for further logins.
            // if the user choose to skip. the user will be required to verify again before booking.
            if (isVerificationByEmail && this.verificationConfig.mobile && !this.verificationConfig.email && !customer.verified) {
                this.router.navigate(['auth/login/mobile'], {queryParams: {returnUrl: this.queryParamsUrl}});
                return;
            }

            if (!isVerificationByEmail && (this.verificationConfig.email && !this.verificationConfig.mobile) && !customer.emailVerified) {
                this.router.navigate(['auth/login/email'], {queryParams: {returnUrl: this.queryParamsUrl}});
                return;
            }

            // this.router.navigateByUrl('branches/list/'); // Main page this.returnUrl
            this.router.navigate(['auth/enable-location'], {queryParams: {returnUrl: this.queryParamsUrl}});
        }, (err) => {
            this.presentPopup(this.translate.instant('PAGES.VERIFICATION.CANNOT_VERIFY_CODE'));
            // const initialNotice = `Cannot verify code`;
            // this.authNoticeService.setNotice(initialNotice, 'danger');
            this.cdr.markForCheck();
            this.splitInputService.clearSplitInput();
        }).finally(() => {
            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    isEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    getConf() {
        let companyId = this.webbookingService.getCompany()._id;
        this.companyService.getVerificationConfigurations(null, companyId).toPromise().then((res) => {
            let arr = res.data?.company?.methods?.mobile;
            this.verificationConfig = res.data?.company;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== 'firebase') {
                    this._altMethodsQueue.push({...this.getMethod(arr[i]), method: arr[i]});
                }
            }
        }).finally(() => {
            this.cdr.markForCheck();
        });
    }

    getMethod(name) {
        if (this.altMethods[0].sms.values.includes(name)) {
            return this.altMethods[0].sms;
        }

        if (this.altMethods[1].call.values.includes(name)) {
            return this.altMethods[1].call;
        }

        if (this.altMethods[2].whatsapp.values.includes(name)) {
            return this.altMethods[2].whatsapp;
        }
    }


    presentPopup(msg) {
        let instance = this.modalService.open(PrebookingPopupComponent, {
            centered: true,
        });
        instance.componentInstance.options = {
            text: msg,
            serviceName: this.translate.instant('PAGES.GENERAL.OOPS'),
            downloadApp: false,
            cancelButton: {active: true, text: this.translate.instant('PAGES.GENERAL.OK')}
        };
        // instance.result.then((result) => {
        // });
    }

    changeAuthParam() {
        this.store.pipe(select(currentUser)).subscribe((customer) => {
            if (customer) {
                let isVerificationByEmail = this.isEmail(this.mobileNumber);
                if (!isVerificationByEmail && this.verificationConfig.mobile && !this.verificationConfig.email && !customer.verified) {
                    this.router.navigate(['auth/login/mobile'], {queryParams: {returnUrl: this.queryParamsUrl}});
                } else if (isVerificationByEmail && this.verificationConfig.email && !this.verificationConfig.mobile && !customer.emailVerified) {
                    this.router.navigate(['auth/login/email'], {queryParams: {returnUrl: this.queryParamsUrl}});
                } else {
                    this.router.navigate(['auth'], {queryParams: {returnUrl: this.queryParamsUrl}});
                }
            } else {
                this.router.navigate(['auth'], {queryParams: {returnUrl: this.queryParamsUrl}});
            }
        });

    }

}
